export default {
  dataplanenquiry:{
    //需要日文翻译
    title:'問い合わせ',
    desc: "您可以於行動服務功能頁查看數據使用情況， 或於上方輸入訂單編號以查詢網卡使用情形。",
    support:"線上技術支持",
    golive:"客服上線時間 09:30 - 22:30 (香港 UTC + 8:00)(不提供語音通話服務)。",
    message1:"* QR code僅支援單次安裝, 若已安裝成功, QR code將無法再被掃描",
    message2:"* 套餐時間結束後QR code將不再顯示",
    contact:'聯繫微信客服',
    iptplaceholder:'請輸入訂單編號',
    orderenquery:'查詢',
    type:'類型',
    activationtime:'啟用時間',
    expirationtime:'過期時間',
    terminatetime:'終止時間',
    statuinactive:'未啟用',
    statususing:'使用中',
    usage:'用量 (MB)',
    smdpaddress:'SM - DP + 位址',
    activationcode:'啟用碼',
    copyalready:'已複製',
    resetTime:'套餐重置时间',
    installeSIM:'立即安裝 eSIM',
    clickInstallEsim:'點擊此按鈕可立即安裝 eSIM',
    notImportQuery:'不支援查詢，請聯絡TSim客服。',
    

    today:'今日',
    remainingdays:'残り日数',
    days:'日',
    bedelayed:'データ使用状況が遅延する場合があります。',
    activationTime2:'有効化時間',
    expirationTime2:'失効時間',
    terminateTime2:'終了時間',
    dailyresettime:'毎日初期化時間',
    eSIMinfo :'eSIM情報',
    timeZone:'タイムゾーン',
    totalUsage:'総使用量',
    unlimited:'無制限',
    notsupportInquiries:'問い合わせには対応しておりませんので、TSim CSまでお問い合わせください。',
    purchaseagain:'再度購入します',
    entertheCardNumber:'ICCIDを入力し、データ使用量をご確認ください。',
    notInquiries:'対応しておりません。',
    copied:'コピー済み',
    copyError:'コピー失敗',
    activationCode:'アクティベーションコード',
    smdpAddress:'SM-DP+アドレス',
    installbtn:'eSIMをワンクリックインストールする',
    inactive:'未使用',
    using:'使用中',
    expired:'期限切れ',
    terminated:'終了済み',
    cancel:'キャンセル済み',
    pending:'処理中',
    method1:'方法1:QRコードのスキャン',
    method2:'方法2:eSIM 情報を手入力する',
    daliy:'每日',
},
  common:{
    message: "jpn",
    login:'ログイン',
    logout:'ログアウト',
    register:'登録',
    forgot:'パスワードが分からない場合',
    product: 'TOP',
    order:'追加プラン',
    enquiry:'現在の利用状况及び使用履歴',
    cancel:'キャンセル',
    ok:'確認',
    logout_text:'ログアウトすることを確認しますか？',
    previous:'上一页',
    next:'下一页',
    video:'ご利用方法を動画で確認',
    loginTips:'最初にログインしてください',
    welcomeTo:'ようこそ TSim Tech!',
    welcomeBack:'おかえりなさい TSim Tech!'
  },
  home:{
    search_1:"探す",
    search_2:'検索結果',
    search_3:'見つかりません',
    search_4:'2語以上入力してください',
    from:'から',
    title:'すべてのデータプラン',
    subtitle:'ご利用の国・通信キャリア・利用期間　を選択ください。',
    result:'クラウドストレージプランなし',
    model_Dear:'親愛なるお客様へ、',
    model_content1:'2024年10月4日から10月10日まで、すべての製品は注文確認ページで10％の割引を受け取れます。',
    // model_content2:'お客様のご理解に感謝し、10％の割引を提供いたします。ご理解のほど、よろしくお願い申し上げます。',
    model_fotter1:'Japan and Global SIM',
    model_fotter2:'TSim Tech Co., Ltd',
  },
  modal:{
    email:'メールアドレスを入力して',
    password:'パスワードを入力する',
    firstName:'名前を入力',
    lastName:'姓を入力してください',
    signNow:'今すぐ登録',
    forgotTitle:'パスワードをリセットするためのメールを送信します',
    forgotTips:'パスワードを変更するためのリンクが記載されたメールをお送りします'
  },
  details:{
    pay:'追加',
    data:'仕様：',
    days:'日々：',
    card:'ICCID',
    verify_1:"カードICCID入力",
    verify_2:"なが長さは15から21",
    verify_3:"正しいカード番号を入力してください",
    disabled:'利用できません',
    instruction:'《サービスのご案内》',
  },
  orders:{
    none:'まだ注文はありません',
    region:'行き先',
    time:'注文時間',
    payment:'実際のお支払い金額',
    status:'注文の状況',
    all:'すべて',
    nonePay:'未払い',
    fulfilled:'元に戻す',
    unfulfilled:'まだ完了していません',
  },
  enquiry:{
    verify:'カードICCIDを入力してください',
    dataPlan:'パッケージ',
    status:'スターテス',
    usagen:'使用量（MB）',
    actived:'有効時間',
    // expired:'有効期限',
    end:'終了時間',
    booking:'予約時間',
    inactive:'有効になっていません',
    using:'使用する',
    expired:'期限切れ',
    terminated:'使用をやめる',
  },
  footer:{
    release:'リリース',
    release_text:'2024年10月24日よりヨーロッパ－新プラン！',
    viewmore:'今すぐクリックして確認してください。',
    links:'クイックリンク',
    service:'規約と条件（提供条件書）',
    refund:'払い戻し条件',
    privacy:'プライバシーポリシー',
    conditions:'重要事項説明',
    transactions:'特定商取引法に関する表示'
  },
  country:{
    asia:'アジア 8 か国',
    australia:'オーストラリア',
    Bali:'バリ',
    China:'中国',
    Denmark:'デンマーク',
    Dubai:'ドバイ',
    europe:'ヨーロッパの複数の目的地',
    guam:'グアム、サイパン、テニアン',
    hawaii:'ハワイ',
    hongkong:'香港、マカオ',
    iceland:'アイスランド',
    india:'インド',
    indonesia:'インドネシア',
    Israel:'イスラエル',
    japan:'日本',
    korea:'韓国',
    macau:'マカオ',
    mainland:'中国本土、香港、マカオ',
    malaysia:'マレーシア',
    newzealand:'ニュージーランド',
    philippines:'フィリピン共和国',
    saudi:'サウジアラビア王国',
    singapore:'シンガポール',
    switzerland:'スイス',
    taiwan:'台湾',
    thailand:'タイ',
    turkey:'トルコ',
    united:'アラブ首長国連邦',
    usa:'アメリカ合衆国',
    USAcanada:'アメリカ合衆国、カナダ',
    vietnam:'ベトナム',
    Italy:'イタリア',
    unitedkingdom:'イギリス',
    France:'フランス',
    Germany:'ドイツ',
    Spain:'スペイン',
    Portugal:'ポルトガル',
  },
  newplan:{
    destination:'地域',
    day:'Day(s)',
    type:'タイプ',
    options:'データ通信量',
    jpprice:'价格(日币)',
    dailyplan:'毎日制',
    volumeplan:'総量制',
    currentproducts:'既存製品'
  },
  apology:{
    text_1:'Japan&Global SIM Card をお買い上げいただき、誠にありがとうございます。',
    text_2:'現在、通信事業者のサーバーが不安定なため、このSIMカードはチャージできません。',
    text_3:'大変お手数ではございますが、以下内容をご確認頂きます様お願い申し上げます。',
    text_4:'《ご利用予定の携帯電話がe-SIMサービス対応のお客様》',
    text_5:'上記内容をクリック頂きe-SIMサービスを購入すると、1,000円引き(～HKD60)とさせて頂きます。',
    text_6:'クーポンコード欄に',
    text_7:'とご入力下さいませ。',
    text_8:'《ご返金希望のお客様》',
    text_9:'日本/韓国/香港/中国/台湾の銀行振込に対応させて頂いております。',
    text_10:'お名前 :',
    text_11:'銀行名 :',
    text_12:'支店　 :',
    text_13:'普通・当座',
    text_14:'口座番号 :',
    text_15:'1週間以内にご返金致します。ご迷惑をお掛け致しまして申し訳ございません。',
    text_16:'※韓国/香港/中国/台湾のお客様',
    text_17:'Alipay/WeChat/Payme/Linepayなどの他の電子決済をご希望の場合は、直接ご確認させて頂きますので以下内容に記載くださいませ。',
    text_18:'',
    text_19:'日本語のサポート:',
    text_20:'WhatsApp (英語/中国語):',
    text_21:'Line (英語/中国語):',
    text_22:'Email (英語/中国語):',
    text_23:'《新しい商品をご希望のお客様》',
    text_24:'商品の送付先をご教示くださいませ',
    text_25:'＊配送先住所',
    text_26:' 〒 :',
    text_27:'住所 :',
    text_28:'お名前 :',
    text_29:'メール :',
    text_30:'メール内容確認次第「郵便速達」にて送付致します。',
    text_31:'この度は大変ご迷惑をお掛け致しますが何卒よろしくお願いいたします。',
  },
  tsim:{
    service:`
    <h1>規約と条件（提供条件書）</h1>
    <h3>Global SIM 利用規約</h3>
    <p>株式会社3rdcompass（以下「当社」といいます）は、Global SIM （以下「本サービス」といいます）利用規約（以下「本規約」といいます）を以下の通り定め、これにより本サービスを提供します。</p>
    <p>本サービスはTSIM TECH CO.,LIMITED（以下「TSIM TECH」といいます）社が開発ししたプリペイド式ワイヤレスデータ通信サービスを当社との業務提携により日本で販売しているサービスとなります。ワイヤレスデータ通信サービス並びに課金開通作業並びに海外ローミング事業者選定業務はTSIM TECHが行い、当社は日本国内における販売及び取次事業のサービスを指します。</p>
    <h3>第1条（定義）</h3>
    <p>本規約における用語を以下のとおり定義します。</p>
    <p>「本サービス」とは当社が提供するプリペイド式ワイヤレスデータ通信サービスをいいます。</p>
    <p>「本SIMカード」とは、本規約に基づき購入された、契約者情報を記録したICカードをいい、本SIMカードには、標準SIMカード、microSIMカードおよびnanoSIMカードの３つのSIMカード種別が含まれるものとします。</p>
    <p>「契約者」とは、本サービスを利用する個人をいいます。</p>
    <p>「購入者」とは、本サービスを利用するために本ＳＩＭカードを購入した個人をいいます。</p>
    <p>「携帯電話事業者」とは、TSIM TECHとワイヤレスデータ通信の提供にかかる相互接続協定その他の契約を締結している携帯電話事業者をいいます。</p>
    <p>「ワイヤレスデータ通信」とは、携帯電話事業者が提供する無線データ通信でパケット交換方式により符号の伝送を行うためのものをいいます。</p>
    <p>「付加機能サービス」とは、当社が定める付加機能サービスをいいます。</p>
    <p>「契約者回線」とは、本サービスにかかる契約に基づいて、契約者が利用する電気通信回線をいいます。</p>
    <p>「端末機器」とは、端末機器の技術基準適合認定等に関する規則（平成16 年総務省令第15 号）で定める種類の端末設備の機器をいいます。</p>
    <p>「協定事業者」とは、当社と相互接続協定その他の契約を結んだ電気通信事業者をいいます</p>
    <h3>第2条（本規約）</h3>
    <p>契約者は、本規約並びにその他本サービスに関する諸規定に従って本サービスを利用するものとします。</p>
    <p>当社は本規約を変更することがあります。この場合には、本サービスの利用条件は変更後の規約によります。</p>
    <p>当社は、本規約を変更した場合、当社ホームページ上または本サービス上で公表するものとし、公表時に指定した改定日から変更後の規約の効力が生じるものとします。</p>
    <p>当社は、本サービスおよび本サービス内容について、予告なく変更または終了する場合があります。</p>
    <p>本規約は日本語で書かれたものであり、本規約の翻訳版と日本語版に相違がある場合には、日本語版の内容が優先されるものとします。</p>
    <h3>第3条（個人情報の取扱に関する方針）</h3>
    <p>当社は、契約者から取得した個人情報を当社の「個人情報の取扱いについて」の利用目的の範囲で利用するものとし、契約者は承諾の上本サービスを申込むものとします。</p>
    <h3>第4条（本サービスおよび付加機能サービスの申込および利用可能期間）</h3>
    <p>本サービスの利用契約は、購入者が本規約に同意のうえで、当社指定の方法にて本サービスへの申込し、当社が当該購入者を契約者として登録した時点をもって成立するものとします。ただし、購入者が購入した本ＳＩＭカードにアクティベーション設定日の期限がある場合、購入者はその設定期限日までに当社指定の方法にて本サービスへ申込するものとします。</p>
    <p>当社は、購入者が購入した本ＳＩＭカードにアクティベーション設定日の期限がある場合、購入者がその設定期限日までにアクティベーションを行わなければ、購入者が本サービスを放棄したものとみなします。また、本ＳＩＭカードに有効期限がある場合、購入者がその有効期限日までにアクティベーションを行わなければ、購入者が本サービスを放棄したものとみなします。購入者が本サービスを放棄した際、当社は購入者に本ＳＩＭカードの購入代金を返金しないものとします。</p>
    <p>契約者は、契約回線のデータ容量を増やす、もしくは利用期間の変更並びに利用国の選択が可能な商品にて契約回線のデータ選択を希望した場合、当社指定方法にて申込み、当社が承諾することにより、契約回線のデータ容量が増加し、利用可能期間の延長が更新されます。尚、当社の承諾は業務提携先であるTSIM TECHが全て責任をもって対応致します。</p>
    <h3>第5条（本サービスの利用申込の承諾）</h3>
    <p>第4条（本サービスおよび付加機能サービスの申込および利用期間）に定める申込について、購入者が以下のいずれかに該当することを当社が確認した場合、当社はその申込を承諾しない場合があり、本サービスの利用申込者は予めこれを了承するものとします</p>
    <p>・利用申込に当たり、虚偽の記載、誤記、記載漏れまたは入力漏れがあった場合。</p>
    <p>・過去に、本サービスまたは当社のその他のサービスの利用資格の停止または失効を受けた場合。</p>
    <p>・その他、業務の遂行上または技術上、支障を来たすと、当社が判断した場合。</p>
    <h3>第6条（本サービスの利用）</h3>
    <p>契約者は、本規約にて明示的に定める場合を除き、本サービスを通じて発信する情報、および本サービスの利用につき一切の責任を負うものとし、他の契約者、第三者および当社に何等の迷惑をかけず、かつ損害を与えないものとします。</p>
    <p>本サービスの利用に関連して、契約者が他の契約者、第三者または当社に対して損害を与えた場合、あるいは契約者と他の契約者または第三者との間で紛争が生じた場合、当該契約者は自己の費用と責任でかかる損害を賠償またはかかる紛争を解決するものとし、当社に何等の迷惑をかけず、かつ損害を与えないものとします。</p>
    <h3>第7条（通信区域）</h3>
    <p>本サービスの通信区域は、携帯電話事業者の通信区域の通りとします。本サービスは、接続されている端末機器が通信区域内に在圏する場合に限り行うことができます。ただし、当該通信区域内であっても、屋内、地下駐車場、ビルの陰、トンネル、山間部等電波の伝わりにくい場所では、通信を行うことができない場合があります。</p>
    <p>前項の場合、契約者は当社に対し、当社の故意または重大な過失により生じた場合を除き、本サービスが利用できないことによるいかなる損害賠償も請求することはできません。</p>
    <h3>第8条（利用制限）</h3>
    <p>当社は、技術上、保守上、その他当社の事業上やむを得ない事由が生じた場合、または携帯電話事業者の提供する電気通信サービスの契約約款の規定もしくは携帯電話事業者と当社との間で締結される契約の規定に基づく、携帯電話事業者による通信利用の制限が生じた場合、通信を一時的に制限することがあります。</p>
    <p>契約者は当社に対し、当社の故意または重大な過失により生じた場合を除き、通信が制限されることによるいかなる損害賠償も請求することはできません。</p>
    <h3>第9条（時間等制限）</h3>
    <p>前条の規定による場合のほか、当社は、通信が著しく混線及び混雑するときは、通信時間または特定の地域の通信の利用を制限することがあります。</p>
    <p>前項の場合において、天災、事変その他の非常事態が発生し、または発生するおそれがある場合の災害の予防もしくは救援、交通、通信もしくは電力の供給の確保または秩序の維持のために必要な事項を内容とする通信および公共の利益のために緊急を要する事項を内容とする通信を優先的に取り扱うため、電気通信事業法施行規則の規定に基づき総務大臣が告示により指定した機関が使用している移動無線装置（当社または携帯電話事業者がそれらの機関との協議により定めたものに限ります）以外のものによる通信の利用を中止する措置（特定の地域の契約者回線等への通信を中止する措置を含みます）をとることがあります。</p>
    <p>当社は、一定期間における通信時間が当社の定める時間を超えるとき、または一定期間における通信容量が当社の定める容量を超えるときは、その通信を制限、もしくは切断することがあります。</p>
    <p>当社は、契約者間の利用の公平を確保し、本サービスを円滑に提供するため帯域を継続的かつ大量に占有する通信手順を用いて行われる通信について速度や通信量を制限することがあります。</p>
    <p>前４項の場合、契約者は当社に対し、通信時間等が制限されることによるいかなる損害賠償も請求することはできません。</p>
    <p>当社は、本条に規定する通信時間等の制限のため、通信にかかる情報の収集、分析および蓄積を行うことがあります。</p>
    <h3>第10条（通信容量の測定）</h3>
    <p>本サービスにかかる通信容量の測定方法は、次の通りとします。</p>
    <p>通信容量は、発信者および着信者双方の契約回線等を接続して通信できる状態にした時刻（その通信が手動接続通信であって通信の相手を指定したものであるときは、その指定した相手と通信することができる状態にした時刻とします）から起算し、発信者または着信者による通信終了の信号を受けその通信をできない状態にした時刻までの容量とし、当社の機器（協定事業者の機器を含みます）により測定します。</p>
    <p>前号の定めに拘らず、契約回線の故障等、通信の発信者または着信者の責めに帰すことのできない事由により通信を一時的に制限されたとき（第8条（通信利用の制限）により通信を一時的に制限された場合は、その制限を通知したときとします）は、協定事業者が別途定める規定による容量を通信容量とします。</p>
    <h3>第11条（通信速度等）</h3>
    <p>当社が本サービス上に定める通信速度は、実際の通信速度の上限を示すものではなく、接続状況、契約者が使用する本SIMカード、情報通信機器、ネットワーク環境、その他の理由により変化し、通信速度が低下するものであることを、契約者は了承するものとします。</p>
    <p>通信速度はベストエフォート方式による提供となり、実際の通信速度は、通信環境やネットワークの混雑状況に応じて変化します。</p>
    <p>当社は、本サービスにおける通信速度について、いかなる保証も行わないものとします。</p>
    <p>契約者は、電波状況等により、本サービスを利用して送受信されたメッセージ、データ、情報等が破損または滅失することがあることを、あらかじめ承諾するものとします。</p>
    <h3>第12条（本SIMカード）</h3>
    <p>本サービスの利用には、本SIMカードが必要となります。</p>
    <p>本SIMカードは、サイズに応じて複数の種類がありますが、かかる購入にあたり、契約者は契約者の責任において、端末機器に適合するサイズのSIMカードを選択いただく必要があります。</p>
    <p>契約者は、本SIMカードを善良なる管理者の注意をもって管理するものとします。</p>
    <p>契約者は、本SIMカードを契約者以外の第三者に利用させたり、貸与、譲渡、売買等をしてはならないものとします。</p>
    <p>契約者による本SIMカードの管理不十分、使用上の過誤、第三者の使用等による損害は契約者が負担するものとし、当社は一切責任を負わないものとします。また、第三者による本SIMカードの使用により発生した料金等については、全て当該SIMカードの管理責任を負う契約者の負担とします。</p>
    <p>契約者の責めに帰すべからざる事由により本SIMカードが故障した場合に限り、当社は当社の負担において本SIMカードの修理若しくは交換（種別の異なるSIMカードの交換はできないものとします。以下同じとします）をする義務を負います。</p>
    <p>契約者は、本SIMカードに登録されている契約者識別番号（ICCID）その他の情報を読出し、変更または消去してはならないものとします。</p>
    <p>契約者は、本SIMカードに、当社、携帯電話事業者および第三者の業務に支障が生じる変更、毀損等をしないものとします。契約者の責めに帰すべき事由により本SIMカードが故障した場合は、その修理若しくは交換の費用は契約者の負担とします。なお、この場合、契約者は、修理若しくは交換のための費用のほか、当社が定めるSIMカード損害金を当社に支払うものとします。</p>
    <p>契約者は、前項に定める場合を除き、本SIMカードの返品または交換ができないことをあらかじめ承諾するものとします。</p>
    <h3>第13条（機器）</h3>
    <p>契約者は、本サービスを利用するために必要となる機器については、契約者が自己の費用と責任において準備および維持するものとします。</p>
    <p>当社は、前項の場合において、契約者または第三者に生じた損害について、一切の責任を負わないものとします。</p>
    <h3>第14条（提供の中断）</h3>
    <p>当社は、次のいずれかに該当する場合には、本サービスの提供を中断することがあります。</p>
    <p>・当社の電気通信設備の保守上または工事上やむを得ないとき。</p>
    <p>・第8条（通信利用の制限）または第9条（通信時間等の制限）により通信利用を制限するとき。</p>
    <p>・携帯電話事業者の約款により通信利用を制限するとき。</p>
    <p>当社は、本条に基づく利用の中断について、損害賠償または本サービスの料金の全部または一部のご返金はいたしません。</p>
    <h3>第15条（利用停止）</h3>
    <p>当社は、本サービスの仕様として定める場合の他、契約者が次のいずれかに該当するときは、当社が定める期間、本サービスの提供を停止することがあります。</p>
    <p>・本サービスに関する申込みについて、申込みの内容が事実に反することが判明したとき。</p>
    <p>・契約者が当社に届出ている情報に変更があったにもかかわらず、当該変更にかかる届出を怠ったとき、または、届出られた内容が事実に反することが判明したとき。</p>
    <p>・第34条（契約者確認）に定める契約者確認に応じないとき。</p>
    <p>・第31条（禁止事項）に定める禁止行為を行ったとき。</p>
    <p>・第13条（機器）の規定に違反し、本SIMカードを技術基準に適合しない機器で利用したとき。</p>
    <p>・当社の業務または本サービスにかかる電気通信設備に支障を及ぼし、または支障を及ぼすおそれのある行為が行われたとき。</p>
    <p>・本サービスが他の契約者に重大な支障を与える態様で使用されたとき。</p>
    <p>・本サービスが違法な態様で使用されたとき。</p>
    <p>・契約者が死亡または清算されたとき。</p>
    <p>・前各号のほか、本規約の定めに違反する行為が行われたとき。</p>
    <p>当社は、本条に基づく本サービスの提供の停止について、損害賠償または本サービスの料金の全部または一部のご返金はいたしません。</p>
    <h3>第16条 (当社による利用契約の解除)</h3>
    <p>当社は、前条第1項の規定により本サービスの提供を停止された契約者が、なおその事実を解消しない場合には、その利用契約を解除することがあります。</p>
    <p>当社は、契約者が前条第1項各号の規定のいずれかに該当する場合で、その事実が当社の業務の遂行上著しい支障が認められるときは、前項の規定にかかわらず、利用停止をしないでその利用契約を解除することがあります。</p>
    <h3>第17条（期限の利益）</h3>
    <p>前2条の規定に基づき、本サービスの提供が停止または本サービスの利用契約が解除された場合、該当する契約者は、期限の利益を失い、かかる本サービスの提供の停止または本サービスの利用契約の解除の日までに発生した本サービスに関連する当社に対する債務の全額を、当社の指示する方法で一括して支払うものとします。</p>
    <h3>第18条（解約）</h3>
    <p>契約者は、本サービスの有効期間中、本サービスの利用契約を解約することができないことをあらかじめ承諾するものとします。</p>
    <p>本SIMカードの修理若しくは交換に際して、修理若しくは交換対応後の本SIMカードを受領いただけない場合は、別途当社の指定する期日をもって本サービスを解約するものとします。</p>
    <h3>第19条（料金）</h3>
    <p>当社が提供する本サービスの料金は、別途当社が定める料金表に定めるところによるものとし、契約者はこれらの料金について支払う義務を負うものとします。</p>
    <p>本サービスにかかる料金は本SIMカードとは別に購入者が選択した内容によるチャージ料金及びリチャージ料金を購入者の選択する方法にて支払うものとします。その料金はTSIM TECHが責任をもって回収し、購入者が選択した内容に即したサービスに充てるものとする。</p>
    <h3>第20条（料金の計算等）</h3>
    <p>料金の計算方法並びに料金の支払方法は、別途当社が定めるところによります。尚、その案内は当社が指定する場所に告知するものとする。</p>
    <h3>第21条（割増金）</h3>
    <p>契約者は、料金の支払いを不法に免れた場合は、その免れた額のほか、その免れた額（消費税相当額を加算しない額とします）の２倍に相当する額に消費税相当額を加算した額（料金表の規定により消費税相当額を加えないこととされている料金にあっては、その免れた額の２倍に相当する額）を割増金として支払っていただきます。</p>
    <h3>第22条（延滞利息）</h3>
    <p>契約者は、料金その他の債務（延滞利息を除きます）について支払期日を経過してもなお支払いがない場合には、支払期日の翌日から支払いの日の前日までの日数について、年14.5％の割合で計算して得た額を延滞利息として支払っていただきます。</p>
    <p>ただし、支払期日の翌日から起算して１５日以内に支払いがあった場合には、この限りではありません。</p>
    <h3>第23条（料金等の変更）</h3>
    <p>当社は、当社が適当と判断する方法で契約者に事前に通知することにより、本サービスの料金およびその支払い方法を変更することができるものとします。ただし、本サービスの料金およびその支払方法の変更の詳細については、当社のホームページ上または適当と判断する場所に掲示することにより、契約者への通知に代えることができるものとします。</p>
    <h3>第24条（当社が行う賠償範囲の限定）</h3>
    <p>当社の責に帰すべき事由により契約者等に損害を与えた場合、当社は契約者等に生じた直接かつ通常の損害に対して、本サービスの利用料金の範囲内で責を負うものとし、逸失利益等の特別な損害に対しては、予見可能性の有無を問わず当社は責を負わないものとします。</p>
    <h3>第25条（免責）</h3>
    <p>電気通信設備の修理、復旧等に当たって、その電気通信設備に記憶されているメッセージ、データ、情報等の内容等が変化または消失することがあります。当社はこれにより損害を与えた場合に、それが当社の故意または重大な過失により生じたものであるときを除き、その損害を賠償する責任を負いません。</p>
    <p>当社は、本規約等の変更により機器の改造または変更（以下この条において「改造等」といいます）を要することとなる場合であっても、その改造等に要する費用については負担しません。</p>
    <h3>第26条（当社の維持責任）</h3>
    <p>当社は、当社の電気通信設備を事業用電気通信設備規則（昭和60年郵政省令第30号）に適合するよう努力します。</p>
    <h3>第27条（契約者の維持責任）</h3>
    <p>契約者は、自己端末機器を、当社の定める技術基準および技術的条件に適合するよう維持していただきます。</p>
    <p>前項の規定によるほか、契約者は、機器（移動無線装置に限ります）を無線設備規則に適合するよう維持していただきます。</p>
    <h3>第28条（修理または復旧）</h3>
    <p>当社は、当社の設置した電気通信設備が故障し、または滅失した場合はすみやかに修理し、または復旧を目指します。ただし、修理または復旧を保証するものではありません。</p>
    <h3>第29条（保証の限界）</h3>
    <p>当社は、通信の利用に関し、当社の電気通信設備を除き、相互接続点等を介し接続している、電気通信設備にかかる通信の品質を保証することはできません。</p>
    <p>当社は、インターネットおよびコンピュータに関する技術水準およびネットワーク自体の高度な複雑さにより、現在の一般的技術水準をもっては本サービスに欠陥または不具合のないことを保証することはできません。</p>
    <h3>第30条（サポート）</h3>
    <p>当社は、契約者に対し、本サービスの利用に関する当社が定める内容の技術サポートを提供します。</p>
    <p>当社は、前項に定めるものを除き、契約者に対し、保守、デバッグ、アップデートまたはアップグレード等のいずれを問わず、いかなる技術的役務も提供する義務を負いません。</p>
    <h3>第31条（禁止事項）</h3>
    <p>契約者は、本サービスの利用にあたって以下の行為を行ってはならないものとします。</p>
    <p>他の契約者、第三者または当社の著作権、その他の知的財産権を侵害する行為。</p>
    <p>他の契約者、第三者の財産、プライバシーもしくは肖像権を侵害する行為。</p>
    <p>他の契約者、第三者を差別もしくは誹謗中傷し、または名誉・信用を毀損する行為。</p>
    <p>詐欺等の犯罪に結びつく行為。</p>
    <p>猥褻、児童ポルノまたは児童虐待にあたる画像、文書等を送信・掲載する行為。</p>
    <p>無限連鎖講（ネズミ講）を開設し、またはこれを勧誘する行為。</p>
    <p>事実に反する情報を送信・掲載する行為、または情報を改ざん・消去する行為。</p>
    <p>当社から事前に承認を得ていない、本サービスを通じてまたは本サービスに関連する営利を目的とする行為、またはその準備を目的とする行為。</p>
    <p>本サービス、または第三者が管理するサーバ等の設備の運営を妨げる行為。</p>
    <p>無断で他の契約者、第三者に広告宣伝もしくは勧誘のメールを送信する行為、大量のメールを送信する等により他の契約者もしくは第三者のメールの送受信を妨害する行為、または受信者が嫌悪感を抱く、もしくはその虞のあるメール（嫌がらせメール）を送信する行為。</p>
    <p>コンピューターウィルス等有害なプログラムを使用もしくは提供する行為、またはそれらを支援、宣伝もしくは推奨する行為。</p>
    <p>他の契約者になりすまして本サービスを利用する行為。</p>
    <p>Webサイトもしくは電子メール等を利用する方法により、他者のID等の情報を、当該情報の属する者の錯誤等によりその者の意図に反して取得する行為。</p>
    <p>法令もしくは公序良俗（売春、暴力、残虐等）に違反し、または他の契約者もしくは第三者に不利益を与える行為。</p>
    <p>本サービスを譲渡、貸与、転売、名義変更、質権の設定その他の担保に供する等の行為</p>
    <p>前各号に定める行為を助長する行為。</p>
    <p>前各号に該当する虞があると当社が判断する行為</p>
    <p>その他、当社が不適切と判断する行為。</p>
    <h3>第32条（位置情報の送出）</h3>
    <p>携帯電話事業者がワイヤレスデータ通信に係る当社及びTSIM TECHとの間に設置した接続点と契約者回線との間の通信中にその当社に係る電気通信設備から携帯電話事業者が別に定める方法により位置情報（その契約者回線に接続されている移動無線装置の所在に係る情報をいいます。以下この条において同じとします）の要求があったときは、契約者があらかじめ当社への位置情報の送出に係る設定を行った場合に限り、その接続点へ位置情報を送出することを、契約者は、あらかじめ承諾するものとします。</p>
    <p>当社は、前項の規定により送出された位置情報に起因する損害については、その原因の如何によらず、一切の責任を負わないものとします。</p>
    <h3>第33条（情報収集）</h3>
    <p>当社は、本サービスに関し、契約者に技術サポート等を提供するために必要な情報を収集、利用することがあります。契約者は、契約者から必要な情報が提供されないことにより、当社が十分な技術サポート等を提供できないことがあることをあらかじめ了承するものとします。</p>
    <h3>第34条（契約者確認）</h3>
    <p>購入者は、本ＳＩＭカードを購入する際、本人確認書類を当社の求めに従い提示を求める場合があります</p>
    <p>携帯電話不正利用防止法に規定するサービスに該当する場合、購入者は同法に規定する契約者の公的本人確認書類を提示するものとします。</p>
    <h3>第35条（本サービスの廃止）</h3>
    <p>当社は、本サービスの全部または一部を変更、追加および廃止することがあります。</p>
    <p>当社は、前項の規定により本サービスを廃止するときは、相当な期間前に契約者に告知します。</p>
    <h3>第36条（本サービスの技術仕様等の変更等）</h3>
    <p>当社は、本サービスにかかわる技術仕様その他の提供条件の変更または電気通信設備の更改等に伴い、契約者が使用する本SIMカードの改造または撤去等を要することとなった場合であっても、その改造または撤去等に要する費用について負担しないものとします。</p>
    <h3>第37条（譲渡禁止）</h3>
    <p>契約者は、契約者たる地位ならびに本規約上契約者が有する権利および義務を当社の事前の同意を得ることなく第三者に譲渡してはならないものとします。</p>
    <h3>第38条（分離性）</h3>
    <p>本規約の一部分が無効で強制力をもたないと判明した場合でも、本規約の残りの部分の有効性はその影響を受けず引続き有効で、その条件に従って強制力を持ち続けるものとします。</p>
    <h3>第39条（協議）</h3>
    <p>当社および契約者は、本サービスまたは本規約に関して疑義が生じた場合には、両者が誠意をもって協議のうえ解決するものとします。</p>
    <h3>第40条（合意管轄）</h3>
    <p>契約者と当社との間で本規約に関連して訴訟の必要が生じた場合、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。</p>
    <h3>第41条（準拠法）</h3>
    <p>本規約の成立、効力、履行および解釈については、日本国法に準拠するものとします。</p>
    <p class="text-end">2022年5月1日　制定</p>
    <h3>◆日本国内取次会社：</h3>
    <p>株式会社3rdcompass （日本）</p>
    <p>〒150-0002 東京都渋谷区渋谷3丁目5番１６号渋谷スクエアビル</p>
    `,
    refund:`
    <h1>払い戻し条件</h1>
    <h3>◆返金・返品に関して</h3>
    <p>原則受け付けておりません。商品に対して不備・破損がある場合には以下内容をご確認頂き、商品のお取替えは購入店（または代理店）迄お問合せ頂きます様お願い申し上げます。</p>
    <p>1）レシートがある</p>
    <p>2）SIMカードが保護カードから取り出されてない(通信が稼働していない)</p>
    <p>3）ICCIDがきちんとわかる(通信利用状態が確認可能です。)</p>
    <h3>◆接続方法に関して</h3>
    <p>問合せセンター：</p>
    <p>《日本国内のみ》※日本国内で通信をご利用する場合をさします</p>
    <p>代理店：イン・プラスコールセンター（365日　9:00-18:00　〈日/英/中/韓〉）</p>
    <p>問合せ先：<a href="https://www.in-plus.co.jp/globalsim-contact/">https://www.in-plus.co.jp/globalsim-contact</a></p>
    <p>＊お問合せフォーム入力後、24時間内にご返信致します。お手数をお掛け致しますが暫くお待ちくださいませ。</p>
    <p>＊ご連絡があるまでに改めて</p>
    <p><a href="https://www.youtube.com/watch?v=e4kawn6hDa8&feature=youtu.be">・https://www.youtube.com/watch?v=e4kawn6hDa8&feature=youtu.be</a></p>
    <p><a href="https://www.youtube.com/watch?v=iowS1LExtC4">・https://www.youtube.com/watch?v=iowS1LExtC4</a></p>
    <p>をご確認頂きます様何卒よろしくお願い申し上げます。</p>
    <p>《日本国以外》</p>
    <p>TSIM TECH</p>
    <p>WhatsApp/Telegram : +852 6014 0451</p>
    <p>LINE：@871lfazn </p>
    <p>Email: adminhk@tsimtech.com　英・中</p>
    <p>＊必ずお伺い致します＊</p>
    <p>1）SIMフリー端末である事/４GがONに/モバイルデータ通信がON</p>
    <p>2）ご購入場所（レシートありのみ対応可能）</p>
    <p>3）ICCID</p>
    <p>接続出来たが通信が出来ないに関して</p>
    <p>通信環境に関してはTSIM TECH社が担当していますので連絡をお願いいたします。</p>
    <p>1.Whatsappに連絡してください。すぐに +852 6014 0451に連絡してください</p>
    <p>→すべてのSIMカードとネットワークサービスは、主要な通信事業者によって提供されています。</p>
    <p>TSIM TECH社が原因究明致します。(その際、必要であれば、携帯電話の設定をお知らせください。）</p>
    <p>2.問題が解決されない場合は、返金詳細をご連絡致します。</p>
    <p>→ただし、SIMカードと機種接続の問題であり、通信業者のネットワークの問題ではない場合払い戻しされません。</p>
    <p>→この点においてTSIM TECH社と購入者の間で協議の上決定されます。</p>
    `,
    privacy:`
    <h1>プライバシーポリシー</h1>
    <p>本サービスはTSIM TECH CO.,LIMITED（以下「TSIM TECH」といいます）社が開発ししたプリペイド式ワイヤレスデータ通信サービスを当社との業務提携により日本で販売しているサービス（以下：「本サービス」といいます）となります。ワイヤレスデータ通信サービス並びに課金開通作業並びに海外ローミング事業者選定業務はTSIM TECHが行い、当社は日本国内における販売及び取次事業のサービスを指します。</p>
    <p>本サービスにおける個人情報保護（プライバシーポリシー）として日本国内における管理運営は株式会社3rdcompass(以下「当社」と言います)が行うため、当社の取り組みについてお伝えいたします。</p>
    <p>当社は、顧客及び当社従業者の個人情報を保護することが当社の社会責務と考え、個人情報の保護に関する法律（以下「個人情報保護法」と記します）その他法令に則り、個人情報保護マネジメントシステムを構築・運用するとともに、その継続的改善を実現するために最新のＩＴ技術の動向、社会的要請の変化、経済環境の変動等を常に意識し取り組みます。尚、本サービスに係る個人情報はTSIM TECHが保管しており、当社は保有しておりません。当社はTSIM　TECHに対して日本法に準ずる運営指導及びアドバイスを行います。</p>
    <h3>◆個人情報の取得について</h3>
    <p>当社では、個人情報（個人に関する情報を含みます）を主に以下の方法により取得し、これらを個人データとして利用、保管等します。</p>
    <p>・ご本人から直接取得する方法</p>
    <p>・第三者から取得する方法</p>
    <p>・当社のサービスの利用等に基づき取得する方法</p>
    <p>・Cookieやウェブビーコン（これらに類似した技術等を含みます）等により取得する方法</p>
    <p>・通話を記録することにより取得する方法や写真、映像等で記録することにより取得する方法</p>
    <p>・他、当社と関わることに基づき取得する方法</p>
    <h3>◆個人情報の利用方法</h3>
    <p>・当社における商品・サービスの提供及びアフターサービスの提供</p>
    <p>・個人情報の取得の目的から見て明らかに利用目的を認められる利用</p>
    <p>・各種料金の請求収納及び債権保全</p>
    <p>・広告及び宣伝及び営業・勧誘に必要情報</p>
    <p>・懸賞及びキャンペーン</p>
    <p>・CSR（企業の社会的責任）に関する活動</p>
    <p>・取引先との契約関係管理</p>
    <p>・従業員等の雇用（採用を含みます。）及び人事管理</p>
    <p>その他、前各号に係る業務の遂行上必要な範囲</p>
    <h3>◆個人情報の非開示</h3>
    <p>当社は、前項に定める場合又は法令に定めのある場合を除き、ご本人の個人情報を第三者に開示することはありません。ただし、業務遂行上、業務提携会社には記載基準に即して委託する場合があります。</p>
    <h3>◆個人情報の管理</h3>
    <p>当社は取得、利用、保存、提供、削除・廃棄等について、外的要因の把握に努めるとともに、個人情報の保護に関する法律、同法についての経済産業分野におけるガイドライン、その他の関係法令等を遵守するとともにこれらを満たすべく個人データの取扱いや、その責任者・担当者及びその任務等に係る規律を策定し、それを当社従業員に遵守させると共に、組織的安全管理措置、人的安全管理措置、物理的安全管理措置及び技術的安全管理措置を講じます。</p>
    <h3>◆個人情報の苦情及び相談等</h3>
    <p>個人情報の取扱いに関する苦情及び相談、並びに、個人情報の照会及び修正等の希望については、個人情報に関する当社の窓口までご連絡をいただければ、合理的な範囲ですみやかに対応させていただきます。</p>
    <h3>◆個人情報の委託</h3>
    <p>当社は、個人情報の利用方法の範囲内で、当社以外の者に対し、個人情報の取扱いの全部又は一部を委託し又は個人情報を提供する場合があります。この場合、当社は適切な相手先を選定し、委託先において当該個人データが適切に取扱いされるよう適切な契約を締結すると共に、委託先における個人データの取扱い状況を適時把握します。</p>
    <h3>◆開示請求手続について</h3>
    <p>当社は、法で定める開示請求等手続に関して、適切かつ迅速に対応いたします。ご本人がご自身の情報の開示をご希望される場合には、お申し出いただいた方がご本人であることを確認したうえで、合理的な期間及び範囲で回答します。ただし、法令により開示の申し出には応じられない場合があります。</p>
    <h3>◆個人情報の訂正・削除並びに利用停止・消去について</h3>
    <p>当社保有個人データの内容に関して、本人の情報について訂正、追加または削除をご希望される場合には、お申し出いただいた方がご本人であることを確認したうえで、事実と異なる内容がある場合には、合理的な期間及び範囲で情報の訂正、追加または削除をします。</p>
    <p>当社保有個人データに関して、ご本人の情報の利用停止または消去をご希望される場合で、以下の事項のいずれか一つに該当する場合には、お申し出いただいた方がご本人であることを確認したうえで、原則としてご本人の権利利益の侵害を防止するために必要な限度で利用停止または消去します。ただし、法令に基づき利用停止または消去のお申し出に応じられない場合や、法令で認められた代替措置の実施により利用停止または消去に代えることがあります。 なお、個人データの全部または一部を利用停止または消去した場合、不本意ながらご要望に沿ったサービスの提供ができなくなることがあります。</p>
    <h3>◆プライバシーポリシーの変更について</h3>
    <p>・本プライバシーポリシーの内容は、ユーザーに通知することなく、変更することが出来るものとします。</p>
    <p>・当社が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載した時から効力を生じるものとします。</p>
    <h3>◆開示等の受付方法・窓口</h3>
    <p>個人情報の取扱い等に関連するお問い合わせは、下記のお問合せ窓口までお願いいたします。</p>
    <p>≪お問合せ窓口≫ ※本サービスについて</p>
    <p>住所 〒150-0002 渋谷区渋谷3-5-16スクエアビル2F</p>
    <p>株式会社3rdcompass  個人情報お問合せ窓口</p>
    <p>Mail：privacy★3compass.net   （★を＠に変更してご返信ください）</p>
    <p>※最長で 10 営業日以内にご返信するように致します。</p>
    <h3>※本サービスの個人情報について</h3>
    <p>TSIM TECH CO.,LIMITED</p>
    <p>住所： Unit 1101-1102, Times Tower, 928 Sheung Sha Wan Road, Lai Chi Kok, Kowloon, Hong Kong</p>
    <p>WhatsApp/Telegram : +852 6014 0451</p>
    <p>LINE：@871lfazn</p>
    <p>Mail: adminhk★tsimtech.com（★を＠に変更してご返信ください）</p>
    <p> ※最長で 10 営業日以内にご返信するように致します。</p>
    `,
    conditions:`
    <h1>重要事項説明</h1>
    <p>「Global SIM」 (以下、 「本サービス」といいます。)とは、 国際ワイヤレスローミングサービスを利用した通信回線の提供になります。4G対応の iPhone、 スマートフォン、iPad 又はタブレット等で、 前払いを受けた金額に応じて設定されるデータ量又は利用可能期間に限定して、 ウェブなどのパケット通信を定額で利用できるサービスです。</p>
    <h3>◆データのご利用·追加プラン購入</h3>
    <p>・本サービスには、ご利用者が選択されたことによって付与されるデータ量上限・選択国内利用及び利用期間を利用可能となります。</p>
    <p>・ご利用のデータ量がデータ量上限を超えた場合又は利用可能期間が終了した場合に、 再度ご希望の利用条件を選択する事で引き続きパケット通信がご利用いただけます</p>
    <p>・ご利用開始時刻から２４時間目を１日と致します。</p>
    <p>・データプラン選択後、ご利用開始可否に限らず決済完了後1日目が開始致します。速やかに通信開始手続きをお願いいたします。</p>
    <p>・データプラン選択後、データ量及び利用日数は変更できず、選択内容が双方共完了しない場合には次のデータプラン選択はできません。</p>
    <p>・データ量上限を超えた場合、 利用可能期間は残日数にかかわらず、終了します。</p>
    <p>・データ量上限を超えた場合又は利用可能期間が終了した場合、 パケット通信の利用を停止します。 </p>
    <p>・追加プラン購入される場合は、専用ウェプでお申し込みが必要です</p>
    <p>・音声通話、SMS、MMS、 TVコール、 64K デジタルデータ通信のご利用はできません。</p>
    <p>・通信速度は、送受信時の技術規格上の最大値であり、実際の通信速度を示すものではありません。</p>
    <p>・ベストエフォート方式による提供となり、実際の通信速度は、通信環境やネットワークの混雑状況に応じて変化します。</p>
    <p>・一定時間以上にわたって接続が継続されているもしくは、無通信状態が続く場合には、当該接続を切断する場合があります。</p>
    <p>・本サービスでは電波を使用しているため、サービスエリア内でも屋内や周辺の障害物（建物・地形）などによりご使用になれないことがあります。また、高層ビル、マンションなどの高層階で見晴らしの良い場所であっても、ご使用になれない場合があります</p>
    <p>・本サービスでは海外でのご利用が可能です。詳細は専用サイトをご確認ください。</p>
    <p>・本サービスの利用の開始をした日から起算して36５日後に本サービスの契約は解除となります。</p>
    <p>・一定期間に大量の通信をご利用されるお客さまに対して、 通信速度を制限する場合があります。 また、 よりよいネットワーク品質を提供するため通信毎にトラフィック情報の収集、 分析、蓄積を行い、 当社が別に定める通信において、 制限を行う場合があります。</p>
    <p>・本サービスにご利用の場合、 テザリングオプションが無料でご利用いただけます(ご利用の機種の種類等により、 ご利用になれない場合があります)。</p>
    <h3>◆SIMカードの取扱いについて</h3>
    <p>・取扱いを誤ると、SIMカード・端末を破損してしまう場合がありますのでご注意ください。</p>
    <p>・金属端子部分は触れないようにし、いつもきれいな状態でご利用ください。</p>
    <p>・金属端子部分に触れてしまった場合は乾いた柔らかい布で拭いてからご使用ください。</p>
    <p>・保管の際には、直射日光があたる場所や高温多湿の場所には置かないでください。</p>
    <p>・お客様ご自身でSIMカードに登録した情報は、別にメモを取るなどして保管してください。万が一、登録された情報が消失してしまうことがあっても、弊社としては責任を負いかねます。</p>
    <h3>◆安全にご注意ください。</h3>
    <p>・SIMカードを取り外す際には手袋を着用し、必ず電源を切った状態で行ってください。</p>
    <p>・子供が使用する場合は、保護者が取り扱いの内容を教えてください。また使用中においても、指示どおりに使用しているかをご注意ください。</p>
    <p>・電子レンジなどの過熱調理器や高圧容器にSIMカードを入れないでください。</p>
    <p>・SIMカードを火やストーブの側など、高温の場所で使用、放置しないでください。溶損、発熱、発煙、火炎やデータの消失、故障の原因となります。</p>
    <p>・乳幼児の手の届かないところに保管してください。誤って飲み込むなどの事故及びけがなどの原因となります。</p>
    <h3>【重要事項説明及び提供条件書記載事項の変更について】</h3>
    <p>当社は、本重要事項説明及び提供条件書記載事項（以下：本提供条件書）の記載事項を変更することがあります。 この場合には、料金その他の提供条件は、 変更後の提供条件書の記載事項によります。</p>
    <p>本提供条件書の記載事項を変更する場合、当社ホームページに掲載する方法、 文字メッセージ(契約者回線又は当社電気通信設備から送信された数字、 記号及びその他文字等によるメッセージをいいます)を配信する方法、 又は当社が適当と判断する方法にて事前に通知します。</p>
    <p>最新の提供条件書は、 当社ホームページに掲載いたしますので、ご確認ください。 </p>
    <p>専用サイト：http://www.esimplus.jp/</p>
    `,
    transactions:`
    <h2>特定商取引法に関する表示</h2>
    <p>販売業者　：株式会社3rdcompass </p>
    <p>代表責任者：木村　幸太郎</p>
    <p>所在地　　：〒150-0002 東京都渋谷区渋谷3-5-16渋谷3丁目スクエアビル2F</p>
    <p>電話番号　：050-5369-1166</p>
    <p>電話受付　：10:00～17:00（土日祝日を除く）</p>
    <p>メールアドレス：mob★3compass.net（★を＠に変更してご返信ください）</p>
    <p>ホームページ　：<a href="https://www.esimplus.jp/">https://www.esimplus.jp/</a></p>
    <p>引き渡し時期　：申込時に指定された受取日</p>
    <p>支払方法　　　：クレジットカードによる決済がご利用頂けます。</p>
    `,
    newplan:`
      <h1>2024年10月24日よりヨーロッパ－新プラン！</br>* * 対象地域拡大 ！* *</h1>
      <p>2024年10月24日より、「欧州‧新プラン」が登場致しました！</p>
      <p>これまで「欧州周遊」のみのご紹介でしたが、国‧地域別にする事で更に『お得に』『分かりやすく』なりました！</p> 
      <P>使い勝手も向上し、よりお客様の体験を応援していきます！</P>
      <P>すべての商品プランリストをご覧になりたい場合は、こちらをご覧ください。</P>
    `
  }
}