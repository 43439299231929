export default {
    dataplanenquiry:{
        //需要日文翻译
        title:'查詢用量',
        desc: "您可以於行動服務功能頁查看數據使用情況， 或於上方輸入訂單編號以查詢網卡使用情形。",
        support:"線上技術支持",
        golive:"客服上線時間 09:30 - 22:30 (香港 UTC + 8:00)(不提供語音通話服務)。",
        message1:"* QR code僅支援單次安裝, 若已安裝成功, QR code將無法再被掃描",
        message2:"* 套餐時間結束後QR code將不再顯示",
        contact:'聯繫微信客服',
        iptplaceholder:'請輸入訂單編號',
        orderenquery:'查詢',
        type:'類型',
        activationtime:'啟用時間',
        expirationtime:'過期時間',
        terminatetime:'終止時間',
        statuinactive:'未啟用',
        statususing:'使用中',
        usage:'用量 (MB)',
        smdpaddress:'SM - DP + 位址',
        activationcode:'啟用碼',
        copyalready:'已複製',
        resetTime:'套餐重置时间',
        installeSIM:'立即安裝 eSIM',
        clickInstallEsim:'點擊此按鈕可立即安裝 eSIM',
        notImportQuery:'不支援查詢，請聯絡TSim客服。',

        today:'今日',
        remainingdays:'剩余天数',
        days:'天',
        bedelayed:'流量使用情況可能延遲',
        activationTime2:'啟用時間',
        expirationTime2:'過期時間',
        terminateTime2:'終止時間',
        dailyresettime:'套餐重置時間',
        eSIMinfo :'eSIM訊息',
        timeZone:'時區',
        totalUsage:'總用量',
        unlimited:'無限制',
        notsupportInquiries:'不支援查詢，請聯絡TSim客服。',
        purchaseagain:'再次購買',
        entertheCardNumber:'請輸入卡號（ICCID）來查詢數據用量',
        notInquiries:'不支援查詢',
        copied:'已複製',
        copyError:'複製失敗',
        activationCode2:'啟用碼',
        smdpAddress2:'SM-DP+位址',
        installbtn:'點擊此按鈕可立即安裝 eSIM',
        inactive:'未激活',
        using:'使用中',
        expired:'已过期',
        terminated:'已终止',
        cancel:'已取消',
        pending:'待处理',
        method1:'方法一：掃描二維碼',
        method2:'方法二：輸入激活碼信息',
        daliy:'每日',
    }
}